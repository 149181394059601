import React from 'react';
import { URL } from '../variables';

class SelectFaculdade extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      faculdades: [],
      faculdade_select: 'tete',
      loading: false,
      msgErro: '',
      redirect: false
    }
  }

  change_faculdade(event) {
    this.setState({ faculdade_select: event.target.value });

  }

  // renderRedirect = () => {
  //   if (this.state.redirect) {
  //     return <Redirect to={'/'+this.state.faculdade_select.slug} />
  //   }
  // }

  get_faculdades() {
    fetch(`${URL}api/fiscal/get_faculdades`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',

        // 'Authorization': `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);

          this.setState({ loading: false, msgErro: erro, refresh: false, loadingMore: false });
        }
        else {
          let selected = '';
          if (resp.faculdades.length > 0) {

            selected = resp.faculdades[0].slug;
          }
          this.setState({ faculdades: resp.faculdades, loading: false, msgErro: '', faculdade_select: selected });
          // this.setState({ loading: false, refresh: false, loadingMore: false, msgErro: '' });

        }




      } catch (err) {
        console.log(err);
        this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor', refresh: false, loadingMore: false });

      }

    })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?', refresh: false, loadingMore: false });
      });
  }

  componentDidMount() {
    this.setState({ loading: true, msgErro: '' });
    this.get_faculdades();
  }






  render() {
    console.log(this.state.faculdade_select)
    return (
      <div>
        {this.state.loading && <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <div className="spinner-border text-primary" role="status"  >
          <span className="sr-only">Loading...</span>
        </div></div>}
        {this.state.loading==false && <div id="app"
          style={{ height: '100vh', backgroundImage: `url(${require("../assets/images/bg_vestibular.jpeg")})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <nav className="navbar navbar-expand-md navbar-light bg-white shadow-sm">
            <div className="container">
              <a className=" navbar-brand logo"><img src={require('../assets/images/logo_enter.png')} height="50" alt="logo" /></a>

            </div>
          </nav>

          <main className="py-4" style={{ height: '60%', display: 'flex', alignItems: 'center' }}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="card">
                    <div className="card-header" style={{ fontSize: '1rem' }}>Selecione sua Faculdade</div>

                    <div className="card-body">


                      <div className="form-group row">
                        <div className="col-sm-12">
                          {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                          <select value={this.state.faculdade_select} className="custom-select" id="cameras" onChange={this.change_faculdade.bind(this)}>
                            {this.state.faculdades.map((item, id) => (
                              <option key={id} value={item.slug}>{item.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group row mb-0">
                        <div className="col-md-12 ">

                          <a href={'/' + this.state.faculdade_select + '/login'} className="btn btn-primary btn-block btn-lg" id="btn" >
                            Fazer Login
                                      </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>}
      </div>
    );
  }


}

export default SelectFaculdade;
