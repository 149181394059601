import React from 'react';
import ProfileMenu from './ProfileMenu';
import { URL } from '../variables';
import { connect } from 'react-redux';


class HeaderSala extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="topbar" id="header_page">

                <div className="topbar-left" style={{ background: '#ececf1' }}>
                    {this.props.faculdade.logo != null && <a className="logo">
                        <span>
                            <img src={URL + 'storage/' + this.props.faculdade.logo} alt="" height='60' />
                        </span>
                        <i>
                            <img className="img-fluid" src={URL + 'storage/' + this.props.faculdade.logo} alt="" height="100" />
                        </i>
                    </a>}
                </div>
                <nav className="navbar-custom">

                    <ul className="navbar-right d-flex list-inline float-right mb-0">
                        {/* <li style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                this.props.showChatGeral(true);
                            }}>
                                <span>Chat Geral</span>
                            </a>
                        </li> */}
                        <ProfileMenu />


                    </ul>



                </nav>
            </div>

        );
    }


}

const mapsStateToProps = (state) => (
    {
        faculdade: state.AppReducer.faculdade
    }
);

export default connect(mapsStateToProps, {})(HeaderSala);