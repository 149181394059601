import React from 'react';
import Header from './header';

import { URL, URL_SOCKET, TURN_1, TURN_2, TURN_3, TURN_4 } from '../variables';
import { connect } from 'react-redux';
import { mudarUser, logout, mudarProcesso, mudarSocket, mudarSalaScreen, mudarChatOpen } from '../actions/AppActions';
import { Redirect, Link } from 'react-router-dom';
import io from 'socket.io-client';

import moment from 'moment-timezone';
import Chat from './Chat';
import ReportAction from './ReportAction';
import ChatIndividual from './ChatIndividual';
import ReactTooltip from 'react-tooltip';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import HeaderSala from './HeaderSala';

class Sala extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            loading_screen: true,
            redirect: false,
            path: '/',
            salas: [],
            sala_select: {},
            number_online: [],
            alunos_m: [],
            comunicados: [],
            salas_ids: [],
            full_screen_open: false,
            aluno_fullscreen: -1,
            candidatos: [],
            width_candidato_box: 0,
            height_candidato_box: 0,
            alunoChat_select: {},
            candidato_selectAcao: {},
            show_modal_action: false,
            show_modal_chat_geral: false
        }
        this.fullscreen_ref = React.createRef();

        this.list_peers = {};
        this.configuration = {
            iceServers: [
                { urls: "stun:stun.1.google.com:19302" },
                {
                    'urls': 'turn:' + TURN_1 + ':3478?transport=udp',
                    'credential': 'Coconuts69341228#',
                    'username': 'onteste_projetos'
                },
                {
                    'urls': 'turn:' + TURN_2 + ':3478?transport=udp',
                    'credential': 'Coconuts69341228#',
                    'username': 'onteste_projetos'
                },
                {
                    'urls': 'turn:' + TURN_3 + ':3478?transport=udp',
                    'credential': 'Coconuts69341228#',
                    'username': 'onteste_projetos'
                },
                {
                    'urls': 'turn:' + TURN_4 + ':3478?transport=udp',
                    'credential': 'Coconuts69341228#',
                    'username': 'onteste_projetos'
                }

            ],
            sdpSemantics: 'unified-plan'
        };
        this.unmont = false;
        // this.videoRef=null;
    }
    handle_offer_sdp(offer) {
        let sdp = offer.sdp.split('\r\n');//convert to an concatenable array
        let new_sdp = '';
        let position = null;
        sdp = sdp.slice(0, -1); //remove the last comma ','
        for (let i = 0; i < sdp.length; i++) {//look if exists already a b=AS:XXX line
            if (sdp[i].match(/b=AS:/)) {
                position = i; //mark the position
            }
        }
        if (position) {
            sdp.splice(position, 1);//remove if exists
        }
        for (let i = 0; i < sdp.length; i++) {
            if (sdp[i].match(/m=video/)) {//modify and add the new lines for video
                new_sdp += sdp[i] + '\r\n' + 'b=AS:' + '24' + '\r\n';
            }
            else {
                if (sdp[i].match(/m=audio/)) { //modify and add the new lines for audio
                    new_sdp += sdp[i] + '\r\n' + 'b=AS:' + 48 + '\r\n';
                }
                else {
                    new_sdp += sdp[i] + '\r\n';
                }
            }
        }
        return new_sdp; //return the new sdp
    }
    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }


    componentDidMount() {
        // document.getElementById('header_page').classList.add('mouse_come_here');
        // this.mouseEvent = (function (e) {
        //     if (e.clientY < 70 && this.props.chat_open == false) {
        //         document.getElementById('header_page').classList.add('visible');
        //         document.getElementById('header_page').classList.remove('mouse_come_here');
        //     }
        //     else {
        //         document.getElementById('header_page').classList.remove('visible');
        //         document.getElementById('header_page').classList.add('mouse_come_here');
        //     }
        // }).bind(this);
        // window.addEventListener('mousemove', this.mouseEvent);

        let width_candidato_box = window.innerWidth / 6;
        let height_candidato_box = (window.innerHeight - 0) / 4;
        this.setState({ width_candidato_box, height_candidato_box });
        this.resizeEvent = (function (event) {
            console.log(window.innerWidth);
            console.log(window.innerHeight);
            let width_candidato_box = window.innerWidth / 6;
            if (this.props.chat_open == true) {
                width_candidato_box = (window.innerWidth - 300) / 6;
            }
            let height_candidato_box = (window.innerHeight - 0) / 4;
            this.setState({ width_candidato_box, height_candidato_box });

            console.log(event);
        }).bind(this);
        window.addEventListener('resize', this.resizeEvent, true);

        this.props.mudarSalaScreen(true);
        this.get_salas(this.props.match.params.id);

    }

    componentDidUpdate(props) {
        if (props.chat_open != this.props.chat_open) {
            let width_candidato_box = window.innerWidth / 6;
            if (this.props.chat_open == true) {
                width_candidato_box = (window.innerWidth - 300) / 6;
            }
            let height_candidato_box = (window.innerHeight - 0) / 4;
            this.setState({ width_candidato_box, height_candidato_box });
        }
    }

    componentWillUnmount() {
        // window.removeEventListener('mousemove', this.mouseEvent)
        // window.removeEventListener('resize', this.resizeEvent, true);
        this.props.mudarSalaScreen(false);

        clearTimeout(this.logs_timeout);
        clearTimeout(this.timeout_numbers);
        if (this.socket != undefined) {
            this.socket.close();
        }
        this.props.mudarSocket(null);
        this.unmont = true;

    }

    async get_salas(id) {
        this.setState({ loading_screen: true });
        fetch(`${URL}api/fiscal/get_salas/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    // this.props.mudarLoadingHome(false);
                    setTimeout(() => {
                        this.get_salas(this.props.match.params.id);
                    }, 5000);
                }
                else if (this.unmont == false) {
                    this.props.mudarProcesso(resp.processo);
                    let salas_ids = [];
                    let candidatos = [];
                    if (resp.salas.length == 0) {
                        this.setState({ redirect: true });
                        return;
                    }
                    for (let i = 0; i < resp.salas.length; i++) {
                        let alunos = {};
                        salas_ids.push(resp.salas[i].id);
                        // let alunos_lista=[];
                        for (let j = 0; j < resp.salas[i].alunos.length; j++) {
                            candidatos.push({ name: resp.salas[i].alunos[j].name + ' - Sala ' + resp.salas[i].numero, id: resp.salas[i].alunos[j].id, sala_id: resp.salas[i].id });
                            alunos[resp.salas[i].alunos[j].id] = resp.salas[i].alunos[j];
                            alunos[resp.salas[i].alunos[j].id].videoRef = React.createRef();
                            alunos[resp.salas[i].alunos[j].id].desconectado = true;
                        }
                        resp.salas[i].alunos = alunos;
                    }
                    candidatos = candidatos.sort(function (a, b) { return a.name - b.name });
                    this.setState({ candidatos: candidatos, loading_screen: false, salas_ids, comunicados: resp.comunicados, salas: resp.salas, sala_select: { ...resp.salas[0] }, alunos_m: resp.alunos });
                    this.video_init();
                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, 30000);
                }




            } catch (err) {
                console.log(err);
                // this.props.mudarLoadingHome(false);
                setTimeout(() => {
                    this.get_salas(this.props.match.params.id);
                }, 5000);

            }

        })
            .catch((err) => {
                console.log(err);
                // this.props.mudarLoadingHome(false);
                setTimeout(() => {
                    this.get_salas(this.props.match.params.id);
                }, 5000);
            });
    }


    salvar_log() {
        fetch(`${URL}api/fiscal/salvar_log?screen=sala&sala_numero=${this.state.sala_select.numero}&sala_id=${this.state.sala_select.id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    if (this.unmont == false) {
                        this.logs_timeout = setTimeout(() => {
                            this.salvar_log();
                        }, 30000);
                    }
                }
            } catch (err) {
                console.log(err);
                if (this.unmont == false) {
                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, 30000);
                }

            }

        })
            .catch((err) => {
                console.log(err);
                if (this.unmont == false) {
                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, 30000);
                }
            });
    }

    onIceCandidate(peer, evt, aluno_id) {
        if (evt.candidate) {
            this.socket.emit("candidate_to_aluno", JSON.stringify({ "candidate": evt.candidate, admin_id: this.admin_id, faculdade_id: this.props.faculdade.id, aluno_id: aluno_id }));
        }
    }

    video_init() {
        this.admin_id = this.uuidv4();
        this.socket = io.connect(URL_SOCKET, { "transports": ['websocket'], query: { token: this.props.token } });
        this.props.mudarSocket(this.socket);

        // socket.emit("join", {name:'Admin',sala_id:sala_id,faculdade_id:faculdade_id});
        this.socket.on("connect", function (client) {

            // this.socket.emit('join_stream_admin', { sala_id: this.state.sala_select.id, admin_id: this.admin_id, faculdade_id: this.props.faculdade.id })
            this.socket.emit("join", { name: 'Admin', salas_ids: this.state.salas_ids, sala_id: this.state.sala_select.id, faculdade_id: this.props.faculdade.id, admin_id: this.admin_id });

        }.bind(this));

        this.socket.on("candidate", function (msg) {
            // console.log("candidate received");
            if (this.list_peers[JSON.parse(msg).aluno_id] != undefined && this.list_peers[JSON.parse(msg).aluno_id].connectionState != 'closed') {
                this.list_peers[JSON.parse(msg).aluno_id].addIceCandidate(new RTCIceCandidate(JSON.parse(msg).candidate));
            }

        }.bind(this));

        this.socket.on("sdp" + this.admin_id, function (msg) {
            console.log("sdp received");
            let id = JSON.parse(msg).aluno_id;
            if (this.list_peers[id] != undefined) {
                this.list_peers[id].close();
            }
            this.list_peers[id] = new RTCPeerConnection(this.configuration);

            this.list_peers[id].onicecandidate = function (evt) {
                if (!evt.candidate) return;
                // console.log("onicecandidate called");
                this.onIceCandidate(this.list_peers[id], evt, id);
            }.bind(this);

            this.list_peers[id].onaddstream = function (evt) {
                console.log("onaddstream called");
                this.state.sala_select.alunos[id].videoRef.current.srcObject = evt.stream;
                if (this.state.aluno_fullscreen != -1 && Object.values(this.state.sala_select.alunos)[this.state.aluno_fullscreen].id == id) {
                    this.fullscreen_ref.current.srcObject = evt.stream;
                }
                // document.getElementById("video_tag" + JSON.parse(msg).aluno_id).srcObject = evt.stream;
            }.bind(this);
            // list_peers[id].oniceconnectionstatechange = function() {
            //     console.log(list_peers[id].iceConnectionState);
            // }
            // list_peers[id].onconnectionstatechange = function() {
            //     console.log(list_peers[id].connectionState);
            // }   
            var sessionDesc = new RTCSessionDescription(JSON.parse(msg).sdp);
            this.list_peers[id].setRemoteDescription(sessionDesc);
            this.list_peers[id].createAnswer().then(function (sdp) {
                sdp.sdp = this.handle_offer_sdp(sdp)
                console.log('anwser sdp');
                this.list_peers[id].setLocalDescription(new RTCSessionDescription(sdp));
                this.socket.emit("answer", JSON.stringify({ "sdp": sdp, admin_id: this.admin_id, aluno_id: JSON.parse(msg).aluno_id, faculdade_id: this.props.faculdade.id }));
            }.bind(this)).catch(function (e) {
                console.log(e);
            });
        }.bind(this));



        this.socket.on("aluno_desconectou_" + this.props.faculdade.id, function (data) {
            // console.log(video);
            if (data.aluno_id != undefined) {
                if (data.sala_id == this.state.sala_select.id) {

                    let sala_select = this.state.sala_select;
                    console.log(data);
                    console.log('desconectou');
                    if (this.state.aluno_fullscreen != -1 && Object.values(this.state.sala_select.alunos)[this.state.aluno_fullscreen].id == data.aluno_id) {
                        this.fullscreen_ref.current.srcObject = null;
                    }
                    sala_select.alunos[data.aluno_id].desconectado = true;
                    sala_select.alunos[data.aluno_id].videoRef.current.srcObject = null;
                    this.setState({ number_online: data.number, sala_select })
                }
                else {
                    this.setState({ number_online: data.number })
                }
            }
            else {
                this.setState({ number_online: data.number })
            }
        }.bind(this))

        this.socket.on("aluno_conectou_" + this.props.faculdade.id, function (data) {
            console.log(data);
            // $('#online_number').text(data.number);
            if (data.aluno_id != undefined) {
                if (data.sala_id == this.state.sala_select.id) {

                    let sala_select = this.state.sala_select;
                    sala_select.alunos[data.aluno_id].desconectado = false
                    this.setState({ number_online: data.number, sala_select })
                }
                else {
                    this.setState({ number_online: data.number })
                }
            }
            else {
                this.setState({ number_online: data.number })
            }
        }.bind(this))

        //Send the ICE Candidate to the remote peer
        this.timeout_numbers = setTimeout(() => {
            this.get_numbers();
        }, 300000);

    }

    get_numbers() {
        if (this.socket != null) {
            this.socket.emit("get_numbers", { faculdade_id: this.props.faculdade.id, admin_id: this.admin_id });
        }
        this.timeout_numbers = setTimeout(() => {
            this.get_numbers();
        }, 300000);
    }

    receive_all(uuid, sala_id, aluno_id) {
        fetch(`${URL}api/fiscal/receive_all_fiscal`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                uuid,
                sala_id, aluno_id
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {

                }
            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }



    render() {

        return (
            <div>
                {/* <HeaderSala showChatGeral={(e) => {
                    this.setState({ show_modal_chat_geral: e });
                }} /> */}

                {this.state.redirect == true && <Redirect to={this.state.path} />}

                {this.state.loading_screen == false && <div className="row">
                    {/* <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{this.props.faculdade.name}</h4>
                                <p style={{ fontSize: '1rem', color: 'black' }}>Nome do Fiscal: {this.props.user.name}</p>

                                <h4 style={{ color: 'black' }}>Comunicado Geral</h4>


                                <div className="d-flex" style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>
                                    <textarea id="msg_comunicado" placeholder="Escreva um comunicado Geral para enviar aos candidatos" className="form-control" style={{ resize: 'none', height: '100px' }} />
                                    <button className="btn btn-primary" id="enviar_comunicado">
                                        Enviar
                                        </button>
                                </div>

                            </div>
                        </div>
                    </div> */}
                    <div className="col-12">
                        <div className="card" style={{marginBottom:0}}>
                            <div className="card-body" style={{ padding: 0 }}>
                                <div className="row" style={{ padding: '1.25rem' }}>

                                    <div className="col-12" style={{ marginBottom: '1rem' }}>
                                        <div style={{ padding: '1.25rem', position: 'absolute', right: 0 }}>
                                        <button onClick={() => {
                                                this.setState({show_modal_chat_geral:true});
                                            }} className="btn btn-success btn-outline btn-circle m-r-5" ><i
                                                className="mdi mdi-chat"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                    Chat Geral</i></button>
                                            <Link onClick={() => {
                                                let keys = Object.keys(this.list_peers);
                                                for (let i = 0; i < keys.length; i++) {
                                                    this.list_peers[keys[i]].close();
                                                }
                                                for (let i = 0; i < Object.keys(this.state.sala_select.alunos).length; i++) {
                                                    if (this.state.sala_select.alunos[Object.keys(this.state.sala_select.alunos)[i]].videoRef.current != null) {
                                                        this.state.sala_select.alunos[Object.keys(this.state.sala_select.alunos)[i]].desconectado = true;
                                                        this.state.sala_select.alunos[Object.keys(this.state.sala_select.alunos)[i]].videoRef.current.srcObject = null;
                                                    }
                                                }
                                            }} to='/' className="btn btn-secondary btn-outline btn-circle m-r-5" ><i
                                                className="mdi mdi-reply"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                    Voltar</i></Link>

                                          
                                        </div>


                                        <h4 style={{ color: 'black' }}>Salas</h4>
                                        
                                        <p  style={{ color: 'black', whiteSpace: 'pre-line' }}>{this.props.processo.name}
                                        </p>

                                        <div className="m-b-20">
                                            <span style={{ color: 'green', fontSize: 16 }}>Candidatos Online: <span id="online_number">{this.state.number_online.length}</span></span>
                                        </div>
                                    </div>
                                    {this.state.salas.map((item, id) => (

                                        <div key={id} className="col-3" style={{ marginBottom: '0.5rem' }}>


                                            <a onClick={() => {
                                                // if(item.id!=this.state.sala_select.id){
                                                let keys = Object.keys(this.list_peers);
                                                let last_sala = this.state.sala_select.id;
                                                for (let i = 0; i < keys.length; i++) {
                                                    this.list_peers[keys[i]].close();
                                                }
                                                console.log(item);
                                                for (let i = 0; i < Object.keys(this.state.sala_select.alunos).length; i++) {
                                                    if (this.state.sala_select.alunos[Object.keys(this.state.sala_select.alunos)[i]].videoRef.current != null) {
                                                        this.state.sala_select.alunos[Object.keys(this.state.sala_select.alunos)[i]].desconectado = true;
                                                        this.state.sala_select.alunos[Object.keys(this.state.sala_select.alunos)[i]].videoRef.current.srcObject = null;
                                                    }

                                                }
                                                this.setState({ sala_select: item });
                                                this.socket.emit('change_room', { name: 'Admin', salas_ids: this.state.salas_ids, sala_id: item.id, last_sala, faculdade_id: this.props.faculdade.id, admin_id: this.admin_id });
                                                // }
                                            }} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px', border: this.state.sala_select.id == item.id ? '1px solid green' : '1px solid grey' }}>
                                                <span style={{ color: this.state.sala_select.id == item.id ? 'green' : 'grey' }}>Sala {item.numero}</span>
                                                <div >
                                                    {/* <div className="msg_count" style="position: absolute;right:20px;top:10px">
                                        <span>Nova</span>
                                    </div> */}
                                                </div>


                                            </a>

                                        </div>
                                    ))}
                                </div>
                                <hr />





                                <div className="row" style={{ margin: 0, marginRight: this.props.chat_open == true ? 300 : 0 }}>
                                    {Object.values(this.state.sala_select.alunos).map((aluno, id) => (

                                        <div key={id}
                                            // onClick={() => {
                                            //     this.setState({ full_screen_open: true, aluno_fullscreen: id });
                                            //     this.fullscreen_ref.current.srcObject = aluno.videoRef.current.srcObject;
                                            // }}
                                            style={{ position: 'relative', padding: 0, width: this.state.width_candidato_box, height: this.state.height_candidato_box, display: 'flex', justifyContent: 'center', marginTop: '0rem', borderRight: '1px solid grey', borderBottom: '1px solid grey' }}>
                                            <div className="coluna_video"
                                                style={{ width: '100%', height: '100%', background: 'black' }}>
                                                {!this.state.number_online.includes(aluno.id.toString()) && <div className="desconectado{{$aluno->id}}"
                                                    style={{ height: '100%', width: '100%', background: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute' }}>
                                                    <p style={{ color: 'white', fontSize: '14px' }}>Desconectado...</p>
                                                </div>}
                                                <div style={{ borderRadius: 8, height: '100%', display: 'flex' }} className="align-items-lg-start align-items-md-center">
                                                    <video ref={aluno.videoRef} className="video_tag_aluno" autoPlay muted style={{ width: '100%', position: 'absolute', borderRadius: 8 }} ></video>
                                                </div>
                                            </div>
                                            <div style={{ position: 'absolute', color: 'white', boxShadow: '0px 1px 3px 0px rgb(60 64 67 / 30%), 0px 4px 8px 3px rgb(60 64 67 / 15%)', borderRadius: 8, background: 'rgba(0,0,0,0.5)', right: 0, left: 0, bottom: 0, height: 'auto', padding: 5 }}>
                                                <p style={{ margin: 0 }}>{aluno.name}</p>
                                                <div className="row" style={{ margin: 0 }}>
                                                    <div data-tip={"Abrir o chat com o candidato " + aluno.name + ""} className="icon_box" style={{ position: 'relative', marginRight: 2 }} onClick={(e) => {
                                                        // e.stopPropagation();
                                                        let aluno2 = this.state.alunos_m[aluno.id];
                                                        if (aluno2.mensagens.length > 0) {
                                                            this.props.socket.emit('msg_receive_aluno', { sala_id: aluno2.sala_id, aluno_id: aluno2.id, uuid: aluno2.mensagens[aluno2.mensagens.length - 1].uuid, faculdade_id: this.props.faculdade.id });
                                                            this.receive_all(aluno2.mensagens[aluno2.mensagens.length - 1].uuid, aluno2.sala_id, aluno2.id);
                                                        }
                                                        let alunos = { ...this.state.alunos_m };
                                                        alunos[aluno2.id].counter = 0;
                                                        this.setState({ alunos_m: alunos, alunoChat_select: { ...this.state.alunos_m[aluno2.id] } });

                                                        this.props.mudarChatOpen(true);
                                                    }}>
                                                        <ReactTooltip />

                                                        <i style={{ color: 'inherit' }} className="mdi mdi-chat"></i>
                                                        {this.state.alunos_m[aluno.id].counter > 0 && <div style={{
                                                            // padding: '2px',
                                                            // paddingTop: '3px',
                                                            // height:15,
                                                            minWidth: 20,
                                                            background: 'rgb(75, 181, 67)',
                                                            borderRadius: '50px',
                                                            position: 'absolute',
                                                            top: -5,
                                                            textAlign: 'center',
                                                            // paddingBottom: '3px',
                                                            right: -5
                                                        }}>

                                                            <span style={{ color: 'white', textAlign: 'center', fontSize: 11 }}>{this.state.alunos_m[aluno.id].counter}</span>
                                                        </div>}
                                                    </div>

                                                    <div data-tip={"Número de vezes que o candidato " + aluno.name + " saiu da tela"} className="icon_box" style={{ position: 'relative', marginRight: 2 }} >
                                                        <ReactTooltip />
                                                        <span style={{ color: this.state.alunos_m[aluno.id].mensagens.filter((item, id) => item.warning == true && item.message == 'O candidato saiu da página da avaliação').length > 0 ? 'yellow' : 'white', textAlign: 'center', fontSize: 11 }}>{this.state.alunos_m[aluno.id].mensagens.filter((item, id) => item.warning == true && item.message == 'O candidato saiu da página da avaliação').length}</span>

                                                    </div>

                                                    <div
                                                        onClick={() => {
                                                            this.setState({ full_screen_open: true, aluno_fullscreen: id, alunoChat_select: { ...this.state.alunos_m[aluno.id] } });
                                                            this.fullscreen_ref.current.srcObject = aluno.videoRef.current.srcObject;
                                                        }}
                                                        data-tip={"Ver candidato em tela cheia para ouví-lo"} className="icon_box" style={{ position: 'relative', marginRight: 2 }} >
                                                        <ReactTooltip />
                                                        <i style={{ color: 'inherit' }} className="mdi mdi-fullscreen"></i>

                                                    </div>

                                                    <div
                                                        onClick={() => {
                                                            this.setState({ candidato_selectAcao: aluno, show_modal_action: true });
                                                            // this.setState({ full_screen_open: true, aluno_fullscreen: id, alunoChat_select: { ...this.state.alunos_m[aluno.id] } });
                                                            // this.fullscreen_ref.current.srcObject = aluno.videoRef.current.srcObject;
                                                        }}
                                                        data-tip={"Reportar ação do candidato"} className="icon_box" style={{ position: 'relative', marginRight: 2 }} >
                                                        <ReactTooltip />
                                                        <i style={{ color: 'inherit' }} className="fas fa-exclamation-circle"></i>

                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {this.state.alunos_m[aluno.id].finish == 'F' && <div data-tip={"Candidato finalizou a avaliação"} style={{ borderRadius: 100, backgroundColor: '#815ae4', height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 30, height: 30 }}>
                                                            <p style={{ margin: 0, color: 'white', fontSize: 10 }}>F</p>
                                                            <ReactTooltip />
                                                        </div>}
                                                        {this.state.alunos_m[aluno.id].finish == 'I' && <div data-tip={"Candidato não finalizou a avaliação"} style={{ borderRadius: 100, backgroundColor: 'darkgoldenrod', height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 30, height: 30 }}>
                                                            <p style={{ margin: 0, color: 'white', fontSize: 10 }}>NF</p>
                                                            <ReactTooltip />
                                                        </div>}
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div hidden={!this.state.full_screen_open}
                                    style={{ padding: 0, position: 'fixed', top: 0, bottom: 0, left: 0, right: this.props.chat_open == true ? 300 : 0, zIndex: 1000 }}>
                                    <div className="coluna_video"
                                        style={{ width: '100%', height: '100%', background: 'black' }}>
                                        <div onClick={() => {
                                            this.fullscreen_ref.current.srcObject = null;
                                            this.setState({ aluno_fullscreen: -1, full_screen_open: false });
                                        }} style={{ position: 'absolute', top: '10px', right: '10px', color: 'white', zIndex: 5000 }} className="close"><i className="mdi mdi-close" style={{ fontSize: '1.7rem' }}></i></div>
                                        {this.state.alunoChat_select.id != undefined && !this.state.number_online.includes(this.state.alunoChat_select.id.toString()) && this.state.aluno_fullscreen != -1 && <div className="desconectado{{$aluno->id}}"
                                            style={{ height: '100%', width: '100%', backgroundColor: 'rgba(0,0,0,1)', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute' }}>
                                            <p style={{ color: 'white', fontSize: '1rem' }}>Desconectado...</p>
                                        </div>}
                                        <video ref={this.fullscreen_ref} className="video_tag_aluno" autoPlay style={{ height: '100%', width: '100%', position: 'absolute' }} ></video>

                                    </div>
                                    <div onClick={() => {
                                        if (this.state.aluno_fullscreen == 0) {
                                            let indice = Object.values(this.state.sala_select.alunos).length - 1;
                                            this.setState({ aluno_fullscreen: indice, alunoChat_select: { ...this.state.alunos_m[Object.values(this.state.sala_select.alunos)[indice].id] } });
                                            this.fullscreen_ref.current.srcObject = Object.values(this.state.sala_select.alunos)[indice].videoRef.current.srcObject;
                                        }
                                        else {
                                            let indice = this.state.aluno_fullscreen - 1;
                                            this.setState({ aluno_fullscreen: indice, alunoChat_select: { ...this.state.alunos_m[Object.values(this.state.sala_select.alunos)[indice].id] } });
                                            this.fullscreen_ref.current.srcObject = Object.values(this.state.sala_select.alunos)[indice].videoRef.current.srcObject;
                                        }
                                    }} style={{ position: 'absolute', top: '50%', left: '10px', color: 'white', zIndex: 5000 }} className="close"><i className="mdi mdi-arrow-left-thick" style={{ fontSize: '1.7rem' }}></i></div>

                                    <div onClick={() => {
                                        if (this.state.aluno_fullscreen == Object.values(this.state.sala_select.alunos).length - 1) {
                                            let indice = 0;
                                            this.setState({ aluno_fullscreen: indice, alunoChat_select: { ...this.state.alunos_m[Object.values(this.state.sala_select.alunos)[indice].id] } });
                                            this.fullscreen_ref.current.srcObject = Object.values(this.state.sala_select.alunos)[indice].videoRef.current.srcObject;
                                        }
                                        else {
                                            let indice = this.state.aluno_fullscreen + 1;
                                            this.setState({ aluno_fullscreen: indice, alunoChat_select: { ...this.state.alunos_m[Object.values(this.state.sala_select.alunos)[indice].id] } });
                                            this.fullscreen_ref.current.srcObject = Object.values(this.state.sala_select.alunos)[indice].videoRef.current.srcObject;
                                        }
                                    }} style={{ position: 'absolute', top: '50%', right: '10px', color: 'white', zIndex: 5000 }} className="close"><i className="mdi mdi-arrow-right-thick" style={{ fontSize: '1.7rem' }}></i></div>

                                    {/* <div style={{ position: 'absolute', color: 'white', background: 'black', right: 0, left: 0, bottom: 0 }}>
                                        {this.state.aluno_fullscreen != -1 && <p style={{ textAlign: 'center', margin: 0 }}>({this.state.aluno_fullscreen + 1}/{Object.keys(this.state.sala_select.alunos).length}) - {Object.values(this.state.sala_select.alunos)[this.state.aluno_fullscreen].name}</p>}
                                    </div> */}
                                    {this.state.alunoChat_select.mensagens != undefined && <div style={{ position: 'absolute', color: 'white', boxShadow: '0px 1px 3px 0px rgb(60 64 67 / 30%), 0px 4px 8px 3px rgb(60 64 67 / 15%)', borderRadius: 8, background: 'rgba(0,0,0,0.5)', right: 0, left: 0, bottom: 0, height: 'auto', padding: 5 }}>
                                        <p style={{ margin: 0 }}>{this.state.alunoChat_select.name}</p>
                                        <div style={{ display: 'flex' }}>
                                            <div data-tip={"Abrir o chat com o candidato " + this.state.alunoChat_select.name + ""} className="icon_box" style={{ position: 'relative', marginRight: 2 }} onClick={(e) => {
                                                // e.stopPropagation();
                                                let aluno2 = this.state.alunos_m[this.state.alunoChat_select.id];
                                                if (aluno2.mensagens.length > 0) {
                                                    this.props.socket.emit('msg_receive_aluno', { sala_id: aluno2.sala_id, aluno_id: aluno2.id, uuid: aluno2.mensagens[aluno2.mensagens.length - 1].uuid, faculdade_id: this.props.faculdade.id });
                                                    this.receive_all(aluno2.mensagens[aluno2.mensagens.length - 1].uuid, aluno2.sala_id, aluno2.id);
                                                }
                                                let alunos = { ...this.state.alunos_m };
                                                alunos[aluno2.id].counter = 0;
                                                this.setState({ alunos_m: alunos, alunoChat_select: { ...this.state.alunos_m[aluno2.id] } });

                                                this.props.mudarChatOpen(true);
                                            }}>
                                                <ReactTooltip />

                                                <i style={{ color: 'inherit' }} className="mdi mdi-chat"></i>
                                                {this.state.alunos_m[this.state.alunoChat_select.id].counter > 0 && <div style={{
                                                    // padding: '2px',
                                                    // paddingTop: '3px',
                                                    // height:15,
                                                    minWidth: 20,
                                                    background: 'rgb(75, 181, 67)',
                                                    borderRadius: '50px',
                                                    position: 'absolute',
                                                    top: -5,
                                                    textAlign: 'center',
                                                    // paddingBottom: '3px',
                                                    right: -5
                                                }}>

                                                    <span style={{ color: 'white', textAlign: 'center', fontSize: 11 }}>{this.state.alunos_m[this.state.alunoChat_select.id].counter}</span>
                                                </div>}
                                            </div>

                                            <div data-tip={"Número de vezes que o candidato " + this.state.alunoChat_select.name + " saiu da tela"} className="icon_box" style={{ position: 'relative', marginRight: 2 }} >
                                                <ReactTooltip />
                                                <span style={{ color: this.state.alunos_m[this.state.alunoChat_select.id].mensagens.filter((item, id) => item.warning == true && item.message == 'O candidato saiu da página da avaliação').length > 0 ? 'yellow' : 'white', textAlign: 'center', fontSize: 11 }}>{this.state.alunos_m[this.state.alunoChat_select.id].mensagens.filter((item, id) => item.warning == true && item.message == 'O candidato saiu da página da avaliação').length}</span>

                                            </div>

                                            <div
                                                onClick={() => {
                                                    this.setState({ candidato_selectAcao: this.state.alunoChat_select, show_modal_action: true });
                                                    // this.setState({ full_screen_open: true, aluno_fullscreen: id, alunoChat_select: { ...this.state.alunos_m[aluno.id] } });
                                                    // this.fullscreen_ref.current.srcObject = aluno.videoRef.current.srcObject;
                                                }}
                                                data-tip={"Reportar ação do candidato"} className="icon_box" style={{ position: 'relative', marginRight: 2 }} >
                                                <ReactTooltip />
                                                <i style={{ color: 'inherit' }} className="fas fa-exclamation-circle"></i>

                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {this.state.alunos_m[this.state.alunoChat_select.id].finish == 'F' && <div data-tip={"Candidato finalizou a avaliação"} style={{ borderRadius: 100, backgroundColor: '#815ae4', height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 30, height: 30 }}>
                                                    <p style={{ margin: 0, color: 'white', fontSize: 10 }}>F</p>
                                                    <ReactTooltip />
                                                </div>}
                                                {this.state.alunos_m[this.state.alunoChat_select.id].finish == 'I' && <div data-tip={"Candidato não finalizou a avaliação"} style={{ borderRadius: 100, backgroundColor: 'darkgoldenrod', height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 30, height: 30 }}>
                                                    <p style={{ margin: 0, color: 'white', fontSize: 10 }}>NF</p>
                                                    <ReactTooltip />
                                                </div>}
                                            </div>



                                        </div>
                                    </div>}
                                </div>

                                <div className="row" style={{ margin: 0 }}>
                                    <div className="col-12 " hidden={this.state.show_modal_chat_geral == false} style={{
                                        position: 'fixed',
                                        right: 0,
                                        bottom: 0,
                                        left: 0,
                                        top: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        // background: 'rgba(0,0,0,0.4)',
                                        zIndex: 2000
                                    }}>


                                        {this.props.socket != null && <div style={{ zIndex: 3000, width: '100%' }}> <Chat closeChat={() => {
                                            this.setState({ show_modal_chat_geral: false });
                                        }} alunoChat_select={this.state.alunoChat_select} show_modal_chat_geral={this.state.show_modal_chat_geral} online={this.state.number_online} alunos={this.state.alunos_m} admin_id={this.admin_id} comunicados={this.state.comunicados} mudarState={(data) => {
                                            let state = {}
                                            if (data.alunos != undefined) state.alunos_m = data.alunos;
                                            if (data.comunicados != undefined) state.comunicados = data.comunicados;

                                            this.setState(state);

                                        }} /></div>}

                                        <div onClick={() => {
                                            this.setState({ show_modal_chat_geral: false });
                                        }}
                                            style={{
                                                position: 'absolute',
                                                right: 0,
                                                bottom: 0,
                                                left: 0,
                                                top: 0,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                background: 'rgba(0,0,0,0.4)',
                                                zIndex: 2000
                                            }}
                                        >
                                        </div>

                                    </div>
                                    {this.props.socket != null && <div className={this.props.chat_open == true ? 'chat_individual_open' : 'chat_individual_close'} style={{ position: 'fixed', zIndex: 1000, maxWidth: 400, top: 0, bottom: 0, width: 300 }}>
                                        <ChatIndividual aluno_select={this.state.alunoChat_select} online={this.state.number_online} alunos={this.state.alunos_m} admin_id={this.admin_id} comunicados={this.state.comunicados} mudarState={(data) => {
                                            let state = {}
                                            if (data.alunos != undefined) state.alunos_m = data.alunos;
                                            if (data.comunicados != undefined) state.comunicados = data.comunicados;

                                            this.setState(state);

                                        }} />
                                    </div>}
                                  
                                    <div className="col-12 col-md-6 d-flex justify-content-center">
                                        <ReportAction setState={(e) => {
                                            this.setState(e);
                                        }} show_modal_action={this.state.show_modal_action} candidatos={this.state.candidatos} sala={this.state.sala_select} candidato_select={this.state.candidato_selectAcao} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>}
                {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                        <span className="sr-only">Loading...</span>
                    </div>
                </div></div>}
            </div>


        );
    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        socket: state.AppReducer.socket,
        chat_open: state.AppReducer.chat_open,

    }
);

export default connect(mapsStateToProps, { mudarUser, logout, mudarProcesso, mudarSocket, mudarSalaScreen, mudarChatOpen })(Sala);
