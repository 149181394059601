import React from 'react';
import Header from './header';

import { URL } from '../variables';
import { connect } from 'react-redux';
import { mudarUser,logout } from '../actions/AppActions';
import { Redirect, Link } from 'react-router-dom';

import moment from 'moment-timezone';

class ListaProcessos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            loading_screen: false,
            show_video: false,
            show_solicitar: true,
            show_aceitar: false,
            stream: null,
            redirect: false,
            path: '/sala',
            processos:[]
        }
        this.videoRef = React.createRef();

        // this.videoRef=null;
    }
    componentDidMount() {
        this.get_processos();
        this.logs_timeout = setTimeout(() => {
            this.salvar_log();
        }, 30000);
    }

    componentWillUnmount() {
        clearTimeout(this.logs_timeout);
    }

    async get_processos() {
        this.setState({loading_screen:true});
        fetch(`${URL}api/fiscal/get_processos/`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
    
            'Authorization': `Bearer ${this.props.token}`,
            // 'Content-Type': 'application/json',
          }
        }).then(async (responseLog) => {
          try {
            let resp = await responseLog.json();
            console.log(resp);
            if (resp.message == "Unauthenticated.") {
              localStorage.removeItem('token');
              // this.setState({redirect:true,path:'/'});
              this.props.logout();
            }
            if (resp.errors != null || resp.error != null) {
              let errors = Object.values(resp.errors);
              let erro = '';
              for (let i = 0; i < errors.length; i++) {
                if (i != errors.length - 1)
                  erro += errors[i] + '\n';
                else
                  erro += errors[i];
              }
              console.log(erro);
    
              // this.props.mudarLoadingHome(false);
              setTimeout(()=>{
                this.get_processos();
              },5000);
            }
            else {
                this.setState({loading_screen:false,processos:resp.processos});
    
            }
    
    
    
    
          } catch (err) {
            console.log(err);
            // this.props.mudarLoadingHome(false);
            setTimeout(()=>{
              this.get_processos();
            },5000);
    
          }
    
        })
          .catch((err) => {
            console.log(err);
            // this.props.mudarLoadingHome(false);
            setTimeout(()=>{
              this.get_processos();
            },5000);
          });
      }
    

    salvar_log() {
        fetch(`${URL}api/fiscal/salvar_log?screen=lista`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, 30000);
                }
            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }




   

  

   

    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}


                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{this.props.faculdade.name}</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Nome do Fiscal: {this.props.user.name}</p>

                            <hr />
                            <div className="row">

                                <div className="col-12 ">
                                    <div className="form-group row">

                                        <div className="col-12 col-md-12" style={{ objectFit: 'contain' }}>


                                            <div style={{ height: '200px' }}>


                                                <div className="project-item" style={{ marginBottom: 0 }}>
                                                    <div className="overlay-container"
                                                        style={{ height: '200px', display: 'flex', justifyContent: 'center' }}>
                                                        {this.props.faculdade.logo != null && <img src={URL + 'storage/' + this.props.faculdade.logo} alt="img"
                                                            style={{ height: '200px', objectFit: 'contain', borderRadius: '10px' }} />}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>



                            </div>
                            <hr />
                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Lista de Processos</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados todos os processos seletivos que você está vinculado</p>
                            <br />
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                
                                <div className="table-rep-plugin">
                                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                                        <table id="tech-companies-1" className="table table-striped">
                                            <thead>
                                                <tr>

                                                    <th>Nome</th>
                                                    <th>Data de Início</th>
                                                    {/* <th data-priority="1">Descrição</th> */}

                                                    <th data-priority="1">Data de Encerramento</th>
                                                    <th data-priority="3">Ações</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.processos.map((item, id) => (

                                                    <tr key={id}>

                                                        <th>{item.name}</th>
                                                        {/* <td ><div dangerouslySetInnerHTML={{__html:item.descricao}}></div></td> */}
                                                        <td>{moment(item.inicio).format('DD/MM/YYYY HH:mm')}</td>

                                                        <td>{moment(item.fim).format('DD/MM/YYYY HH:mm')}</td>
                                                        <td>
                                                            <Link to={'/sala/' + item.id}><span className="btn btn-success btn-outline btn-circle m-r-5"
                                                                data-toggle="tooltip"
                                                            ><i className="mdi mdi-eye"></i></span></Link>
                                                        </td>

                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {this.state.processos.length == 0 && <div className="row w-100">
                                    <div className="col-12">
                                        <p style={{ textAlign: 'center' }}>Você não está vinculado a nenhum processo</p>

                                    </div>
                                </div>}
                                {/* <div className="row">
                                    <div className="col-12 d-flex justify-content-end">
                                        <ReactPaginate
                                            previousLabel={'Anterior'}
                                            nextLabel={'Próxima'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={this.state.total_pages}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={2}
                                            onPageChange={this.handlePageClick.bind(this)}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                            initialPage={this.state.page - 1}
                                        />
                                    </div>
                                </div> */}

                               

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}
                           


                        </div>
                    </div>
                </div>

            </div>


        );
    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
    }
);

export default connect(mapsStateToProps, { mudarUser,logout })(ListaProcessos);
